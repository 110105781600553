import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { PasswordInput } from 'common/form';
import { Heading } from 'common/typography';
import { Button } from 'common/interaction';
import { Container } from 'common/layout';
import { validation } from 'services';
import { useAuthenticationActions } from 'services/hooks';
import { PageTransition } from 'common/layout/PageTransition';

import {
  PageWrapper,
  HeaderContainer,
  StyledForm,
  ButtonContainer,
  StyledArrow,
} from './styled';

export const NewPasswordForm = () => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const { confirmResetPassword } = useAuthenticationActions();

  const defaultValues = {
    password1: '',
    password2: '',
  };

  const {
    register,
    setError,
    handleSubmit,
    clearErrors,
    formState: {
      isValid,
      errors,
    },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const onSubmit = (data: ResetPasswordData) => {
    const { password1, password2 } = data;

    if (password1 !== password2) {
      setError('password1', {
        message: `${t('Passwords are not equal, please make sure to fill in the same password')}`,
      });
      return;
    }

    clearErrors();
    confirmResetPassword(password1)
      .then(() => {
        navigate('/login');
      })
      .catch(() => {
        setError('password1', {
          message: `${t('something went wrong, please try again later')}`,
        });
      });
  };

  return (
    <PageTransition>
      <PageWrapper>
        <Container>
          <StyledArrow onClick={() => navigate('/login')} />
        </Container>
        <HeaderContainer>
          <Heading as="h2">{t('create your new password')}</Heading>
        </HeaderContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <div>
            <PasswordInput
              label={t('password')}
              error={errors.password1}
              {...register('password1',
                { ...validation.password, ...validation.required })}
            />
            <PasswordInput
              label={t('confirm password')}
              error={errors.password2}
              {...register('password2',
                { ...validation.password, ...validation.required })}
            />
          </div>
          <ButtonContainer>
            <Button
              type="submit"
              size="fullWidth"
              disabled={!isValid}
              $isValid={isValid}
            >
              {t('submit new password')}
            </Button>
          </ButtonContainer>
        </StyledForm>
      </PageWrapper>
    </PageTransition>
  );
};

type ResetPasswordData = {
  password1: string;
  password2: string;
};
