import styled from 'styled-components';
import { Text } from 'common/typography';

import ArrowLeft from 'vectors/arrowLeft.svg';

import { media } from 'styles/utils';

export const HeaderContainer = styled.div`
  margin-bottom: 35px;
  display: flex;
  align-self: start;
  width: 90%;
  margin: 0 auto;

  ${media.desktop`
    width: 60%;
    align-self: center;
  `}
`;

export const StyledArrow = styled(ArrowLeft)`
  &:hover {
    cursor: pointer;
  }
`;

export const ExtendedText = styled(Text)`
  margin-top: 32px;
`;

export const ButtonContainer = styled.div`
  padding-bottom: 30px;
  width: 100%;
  margin: 0 auto;
`;

export const PageWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 0.25fr 1fr;
  height: 100vh;
  margin: 0 auto;

  ${media.desktop`
    grid-template-rows: 0.1fr 0.5fr 1fr;
    height: 90vh;
  `}
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;

  ${media.desktop`
    width: 60%;
  `}
`;
